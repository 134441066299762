<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="临时排班设置"/>
        <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="form">
            <el-form-item label="医生" prop="doctor">
                <radio-doctor v-model="form.doctor"/>
            </el-form-item>
            <el-form-item label="手术室" prop="area">
                <checkbox-area v-model="form.area" :doctor="form.doctor"/>
            </el-form-item>
            <el-form-item label="值班日期" prop="dates">
                <select-dates v-model="form.dates"></select-dates>
            </el-form-item>
            <el-form-item label="时间段" prop="times">
                <time-inputs v-model="form.times"></time-inputs>
            </el-form-item>
            <el-form-item label="备注" prop="desc">
                <el-input type="textarea" v-model="form.desc"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submitForm('form')">保 存</el-button>
                <el-button @click="$router.go(-1)">返 回</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        watch: {
            // eslint-disable-next-line no-unused-vars
            '$route'(to, from) {
                this.getData()
            }
        },
        data() {
            let validateTimes = (rule, value, callback) => {
                let result = false
                value.forEach(e => {
                    if (e.time == '') result = true
                    if (!e.count) result = true
                })
                if (result) {
                    callback(new Error('请输入时间和数量'))
                } else {
                    callback()
                }
            }
            return {
                form: {doctor:'', area:[], dates:[], times: []},
                rules: {
                    doctor: [
                        {required: true, message: '请选择医生', trigger: 'change'}
                    ],
                    area: [
                        {required: true, message: '请选择手术室', trigger: 'blur'}
                    ],
                    dates: [
                        {required: true, message: '请选择日期', trigger: 'blur'},
                    ],
                    times: [
                        {required: true, message: '请输入分时设置', trigger: 'blur'},
                        {validator: validateTimes, trigger: "blur"},
                    ],
                }
            };
        },
        methods: {
            async submitForm(formName) {
                const valid = await this.$refs[formName].validate().then(res => res).catch(error => error);
                if (!valid) {
                    return
                }
                const resp = await this.$http.restPostOrPut('/surgery/overtime/', this.form)
                if (resp.code == 200) {
                    this.$message.success("保存成功！")
                    await this.$router.replace("/main/worksheet/overtime")
                } else {
                    this.$message.error(resp.msg)
                }
            },
            async getData() {
                let id = this.$route.query.id
                if (id) {
                    const resp = await this.$http.get(`/surgery/overtime/${id}`)
                    this.form = resp.data.data
                    // this.form.dates = ['2021-11-11', '2021-11-21']
                }
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style lang="scss" scoped>
    .form {
        margin-top: 20px;
    }
</style>
